/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
    TechRadarLoaderResponse,
    TechRadarApi,
    TechRadarComponentProps,
  } from '@backstage-community/plugin-tech-radar';
 
  export class DFBTechRadarClient implements TechRadarApi {
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
        // if needed id prop can be used to fetch the correct data
        let data;

        if(id?.length !== 0)
        {
            // data = await fetch('https://raw.githubusercontent.com/RoadieHQ/tech-radar/refs/heads/main/tech-radar-2.json').then(res => res.json());
            data = require('../tech-radar/dfb-tech-radar.json');
        }
        else
        {
            data = await fetch('https://bitbucket.dfbnet.org/projects/DEV/repos/backstage/raw/tech-radar/dfb-tech-radar.json').then(res => res.json());
        }
    
        // For example, this converts the timeline dates into date objects
        return {
          ...data,
          entries: data.entries.map((entry: any) => ({
            ...entry,
            timeline: entry.timeline.map((timeline: any) => ({
              ...timeline,
              date: new Date(timeline.date),
            })),
          })),
        };
      }
  }

  export type TechRadarPageProps = TechRadarComponentProps & {
    title?: 'DFB Tech Radar',
    subtitle?: 'Pick the recommended technologies for your projects',
    pageTitle?: 'DFB Tech Radar';
  };